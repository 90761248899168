.poll{
    width:50vw;
    height:50vh;
    background-color:white;
    border-radius:10px;
    color:black;
    display:flex;
    align-items:left;
    justify-content:flex-start;
    padding:20px;
}

.floatingmenu{
    font-size:14px;
    position:absolute;
    top:-15px;
    right:70px;
    font-weight:600;
    display:flex;
    flex-direction:row;
    gap:10px;
}

.counter{
    padding: 5px 10px 5px 10px;
    width:auto;
    height:auto;
}

.counter2{
    width:auto;
    height:auto;
    padding: 5px 10px 5px 10px;
    font-size:14px;
    font-weight:600;
}

.chartbutton{
    width:auto;
    height:auto;
    padding: 5px 10px 5px 10px;
    font-size:14px;
    font-weight:600;
}


.wrapper {
    width:100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    grid-auto-rows: minmax(50px, auto);
    margin-top:15px;
  }

  .card{
      width:50vw;
      position:relative;
      padding-top:10px;
  }

  @media only screen and (max-width: 800px) {
    .card{
        width:90vw;
    }
  }